const newsProd = "https://servicessecure.lexis.com.ec";

const newsDev = "http://localhost:8200";

let entorno = "Prod";

const newsListUrl = entorno === "Prod" ? newsProd : newsDev;
// Define states
const apiStates = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};
//fetch categories
export const listNews = async (bucketName, filePath) => {
  let body = { bucketName, filePath };
  let response = await fetchData(newsListUrl + "/api/v1/lhl/s3/list", body);
  //console.log("Api response:", response)
  return response;
};

//This function fetches data from server
export const fetchData = async (url, bodyQuery) => {
  var fetchedData = "";

  //console.log("previous to fetch")
  await fetch(url, {
    method: "post",
    body: JSON.stringify(bodyQuery),
    headers: { "Content-Type": "application/json" },
  })
    .then((response) =>
      //console.log(response),
      response.ok
        ? response.json()
        : (fetchedData = {
            state: apiStates.ERROR,
            error: response.status,
            data: null,
          })
    )
    .then((data) => {
      if (data.state !== "ERROR")
        fetchedData = {
          state: apiStates.SUCCESS,
          data,
        };
      //console.log(data)
    })
    .catch((err) => {
      //console.log(err)
      fetchedData = {
        state: apiStates.ERROR,
        error: err.status,
        data: null,
      };
    });

  return fetchedData;
};

// Anything exported from this file is importable by other in-browser modules.
export function publicApiFunction() {}
